export const getRandomCategory = () => {
  let randIdx = Math.floor(Math.random() * categories.length);
  console.log("Randomly generated category: " + categories[randIdx]);
  return categories[randIdx];
}

const categories = [
  "Boy Names",
  "Girl Names",
  "Unisex Names",
  "U.S. Cities",
  "Cold Things",
  "Things in a School",
  "Basketball Teams",
  "Soccer Teams",
  "Football Teams",
  "Baseball Teams",
  "Hockey Teams",
  "Bugs",
  "Cold Foods",
  "Hot Foods",
  "Furniture",
  "TV Shows",
  "TV Channels",
  "Movies",
  "Comedy Films",
  "Action Films",
  "Horror Films",
  "Sci-Fi Films",
  "Animated Films",
  "Animated TV Shows",
  "NATO Countries",
  "United Nations Countries",
  "Things in an Ocean",
  "U.S. Presidents",
  "Sold in a Grocery Store",
  "Appliances",
  "Drinks",
  "Personality Traits",
  "Articles of Clothing",
  "Clothing brand",
  "Desserts",
  "Car Parts",
  "Athletes",
  "3-Letter Words",
  "4-Letter Words",
  "5-Letter Words",
  "Animals",
  "Colors",
  "Tools",
  "Fears",
  "Books",
  "Fictional Heroes",
  "Fictional Villans", 
  "White Things",
  "Yellow Things",
  "Blue Things",
  "Red Things",
  "Black Things",
  "Tropical Locations",
  "Arctic Locations",
  "College Majors",
  "Famous Males",
  "Famous Females",
  "Drugs and Medicine",
  "Metal Things",
  "Hobbies",
  "Languages",
  "Healthy Foods",
  "Junk Foods",
  "Things That Grow",
  "Companies",
  "Video Games",
  "Smartphone Apps",
  "Unit of Measurement",
  "Electronics",
  "Board Games",
  "Casino Games",
  "Card Games",
  "Internet Memes",
  "Offensive Words",
  "Wireless Things",
  "Websites",
  "Tourist Attractions",
  "Things Found in a Hospital",
  "Weekend Activities",
  "Acronyms",
  "Holiday Songs",
  "Words Ending in -T",
  "Words Ending in -O",
  "Words Ending in -I",
  "Words Ending in -E",
  "Words Ending in -A",
  "Words Ending in -N",
  "Words With Double Letters",
  "Children's Books",
  "Outdoor Sports",
  "Winter Olympic Sports",
  "Summer Olympic Sports",
  "Indoor Sports",
  "Famous Athletes",
  "Music Albums",
  "Music Bands",
  "Boy Bands",
  "Actors",
  "Actresses",
  "Music Artists",
  "Country Songs",
  "Rock Songs",
  "Hip Hop Songs",
  "Pop Songs",
  "Beatles Songs",
  "Queen Songs",
  "Taylor Swift Songs",
  "Michael Jackson Songs",
  "90s Songs",
  "80s Songs",
  "Cities in Asia",
  "Cities in Europe",
  "Cities in Africa",
  "Countries in Asia",
  "Countries in Europe",
  "Countries in Africa",
  "Zoo Animals",
  "Math Terms",
  "Branch of Science",
  "Words that include the letter Z",
  "Things to do at a Party",
  "World Leaders",
  "School Subjects",
  "Ice Cream Flavors",
  "Stones and Gems",
  "Musical Instruments",
  "Nicknames",
  "Pizza Toppings",
  "Colleges and Universities",
  "Fish",
  "Countries",
  "Historical Figures",
  "Terms of Endearment",
  "Fictional Characters",
  "Harry Potter Characters",
  "Game of Thrones Characters",
  "Sci-Fi Movie Characters",
  "Menu Items",
  "Capital Cities",
  "Candy Brands",
  "Chocolate Brands",
  "Luxury Items",
  "Sports Equipment",
  "Crimes",
  "Sticky Things",
  "Car Brands",
  "Car Models",
  "Greek and Roman Gods",
  "Spices and Herbs",
  "Good Habits",
  "Bad Habits",
  "Celebrities",
  "Influencers",
  "YouTubers",
  "National Parks",
  "Restaurants",
  "Infamous People",
  "Vegetables",
  "Fruits",
  "Toys",
  "Household Chores",
  "Book Authors",
  "Broadway Plays and Musicals",
  "Halloween Costumes",
  "Weapons",
  "Square Things",
  "Round Things",
  "Triangular Things",
  "Exercises",
  "Sports",
  "Songs",
  "Body Parts",
  "Birds",
  "Bedroom Items",
  "Kitchen Items",
  "Living Room Items",
  "Bathroom Items",
  "Flowers",
  "Vacation Spots",
  "Sickness and Disease",
  "Children's Games",
  "Beer Brands",
  "Cocktails",
  "U.S. States",
  "Reusable Items",
  "Disposable Items",
  "Boxer and MMA Fighter",
  "Jobs and Careers",
  "Cartoon Characters",
  "Stores",
  "Trees",
  "Things You Shouldn't Touch",
  "Spicy Foods",
  "Software",
  "Hardware",
  "Things to Do on a Date",
  "Historic Events",
  "Weather",
  "Street Names",
  "Things in a Mall",
  "Verbs",
  "Nouns (not proper)",
  "Adverbs",
  "Adjectives",
  "Onomatopoeias",
  "Elements",
  "Smelly Things",
  "Pet Names",
  "Things Invented after 1900",
  "Holidays",
  "Names of Bodies of Water",
  "Shapes",
  "Dog Breeds",
  "Famous Paintings",
  "Military Equipment",
  "Airports",
  "Religions",
  "Comics"
];
