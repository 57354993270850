import React from 'react';

import HelpIcon from '@mui/icons-material/Help';
import SettingsIcon from '@mui/icons-material/Settings';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import classes from './Header.module.css';

const Header = (props) => {

    return (
    <Box sx={{ flexGrow: 1, m:0 }}>
        <AppBar className={classes.header} position="static">
            <Toolbar>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ }}
                    onClick={props.showHelpDialog}
                >
                    <HelpIcon />
                </IconButton>

                <Typography variant="h4" component="div" sx={{ 
                    flexGrow: 1, 
                    fontFamily: "'Margarine', cursive"
                }}>
                    CA<Box component="span" sx={{ color: '#AE99F5' }}>TICK</Box>ORY
                </Typography>

                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{  }}
                    onClick={props.showStatsDialog}
                >
                    <SettingsIcon />
                </IconButton>
            </Toolbar>
        </AppBar>
    </Box>
    );
};

export default Header;