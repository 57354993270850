import './App.css';
import React, { useState, useEffect } from 'react';
import Header from './components/Header/Header';
import Grid from '@mui/material/Grid';
import Game from './components/Game/Game';
import SetUp from './components/SetUp/SetUp';
import { useLocalStorage } from "./constants/LocalStorage";
import { ThemeProvider } from '@mui/material/styles';
import { lightTheme, darkTheme } from './components/Theme/Theme';
import { CssBaseline } from "@mui/material";
import Link from '@mui/material/Link';

import * as StringUtil from './constants/StringUtil';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import HelpDialog from './components/Dialog/HelpDialog';
import SettingsDialog from './components/Dialog/SettingsDialog';
import TiedDialog from './components/Dialog/TiedDialog';

import tickTockSfx from './assets/sounds/tick.mp3';
import useSound from 'use-sound';

function App() {

  // Game Objects
  const [isPlay, setPlay] = useState(false);
  const [category, setCategory] = useState(null);
  const [timer, setTimer] = useState(null);
  const [excludedLetters, setExcludedLetters] = useState(null);

  // Dialog box
  const [helpDialogShown, setHelpDialogShown] = useState(false);
  const [statsDialogShown, setStatsDialogShown] = useState(false);
  const [tiedDialogShown, setTiedDialogShown] = useState(false);

  // Settings
  const [darkMode, setDarkMode] = useLocalStorage('darkMode', false);
  const [colorBlindMode, setColorBlindMode] = useLocalStorage('colorBlindMode', false);
  const [isNewPlayer, setIsNewPlayer] = useLocalStorage('newPlayer', true);
  const [isSound, setSound] = useLocalStorage('sound', true);
  const [isTickTockSound, setTickTockSound] = useLocalStorage('tickTockSound', true);

  const [playTickTockSfx, optionsTickTockSfx] = useSound(tickTockSfx, {volume: 0.25}); // placed here as hack to fix bug of sound occuring after end game
  const stopTickTockSfx = optionsTickTockSfx.stop;

  useEffect(() => { // On app startup
    // New player pop up
    if (isNewPlayer) {
      setHelpDialogShown(true);
      setIsNewPlayer(false);
    }

    // Game load
    console.log("Initializing app...");
  }, []);

  useEffect(() => {
    if (isPlay && category != null && timer != null && excludedLetters != null) {
      console.log("Starting game: category=\"" + category + "\", timer=" + timer + ", excludedLetters=[" + StringUtil.toString(excludedLetters) + "]"); 
    }
  }, [isPlay, category, timer, excludedLetters]);

  useEffect(() => {
    if (!isSound || !isTickTockSound) {
      stopTickTockSfx();
    }
  }, [isSound, isTickTockSound]);

  const showHelpDialog = () => {
    setHelpDialogShown(true);
  };

  const closeHelpDialog = () => {
    setHelpDialogShown(false);
  };

  const showStatsDialog = () => {
    setStatsDialogShown(true);
  };

  const closeStatsDialog = () => {
    setStatsDialogShown(false);
  };

  const showTiedDialog = () => {
    setTiedDialogShown(true);
  };

  const closeTiedDialog = () => {
    setTiedDialogShown(false);
  };

  const toggleSound = () => {
    setSound(!isSound);
  };

  const toggleTickTockSound = () => {
    setTickTockSound(!isTickTockSound);
  };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const toggleColorBlindMode = () => {
    setColorBlindMode(!colorBlindMode);
  };

  const handleSetPlay = (isPlay) => {
    setPlay(isPlay);
  };

  const handleSetCategory = (category) => {
    setCategory(category);
  };

  const handleSetTimer = (timer) => {
    setTimer(timer);
  };

  const handleSetExcludedLetters = (excludedLetters) => {
    setExcludedLetters(excludedLetters);
  };

  const handleEndGame = () => {
    console.log("Force ending game");
    stopTickTockSfx();
    setPlay(false);
  };

  const handlePlayTickTockSfx = () => {
    if (isSound && isTickTockSound) {
      playTickTockSfx();
    }
  };

  const handleStopTickTockSfx = () => {
    stopTickTockSfx();
  };

  // TODO: 
  // theme color
  // add more categories
  // community?

  return (
    <div className="App">
      <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
        <CssBaseline />

        <Header showHelpDialog={showHelpDialog} showStatsDialog={showStatsDialog} />

        <HelpDialog 
          helpDialogShown={helpDialogShown} 
          onClose={closeHelpDialog} />

        <SettingsDialog 
          statsDialogShown={statsDialogShown} 
          onClose={closeStatsDialog} 
          endGame={handleEndGame}
          isPlay={isPlay}
          toggleSound={toggleSound}
          isSound={isSound}
          toggleTickTockSound={toggleTickTockSound}
          isTickTockSound={isTickTockSound}
          toggleDarkMode={toggleDarkMode}
          darkMode={darkMode}
          toggleColorBlindMode={toggleColorBlindMode}
          colorBlindMode={colorBlindMode} />

        <TiedDialog 
          tiedDialogShown={tiedDialogShown}
          onClose={closeTiedDialog}
          endGame={handleEndGame}
        />

          <main>
            <Container maxWidth="sm">
              <Grid container spacing={1}>
                {!isPlay ? 
                <SetUp 
                  setPlay={handleSetPlay}
                  setCategory={handleSetCategory}
                  setTimer={handleSetTimer}
                  setExcludedLetters={handleSetExcludedLetters} /> 

              : <Game 
                    playTickTockSfx={handlePlayTickTockSfx}
                    stopTickTockSfx={handleStopTickTockSfx}
                    isSound={isSound}
                    category={category.trim()}
                    setCategory={handleSetCategory}
                    timer={timer}
                    excludedLetters={excludedLetters} 
                    showTiedDialog={showTiedDialog} />}
              </Grid>
              
              <Typography sx={{ fontSize: '12px', paddingTop: '2em', paddingBottom: '1em' }} gutterBottom>by <Link color="inherit" target="_blank" href="https://rentumbokon.com">renard</Link></Typography>
            </Container>
          </main>

      </ThemeProvider>
    </div>
  );
}

export default App;
