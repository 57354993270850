export const toString = (set) => {
  if (set == null || !set instanceof Set) {
    return '';
  }
  let str = '';
  set.forEach(val => {
    str += val + ',';
  });
  if (str.length > 0) {
    return str.slice(0, -1);
  }
  return str;
}