import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import classes from './HelpDialog.module.css'
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import SmartphoneOutlinedIcon from '@mui/icons-material/SmartphoneOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import Grid from '@mui/material/Grid';

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function HelpDialog(props) {

  const [firstPlayerPhrase, setFirstPlayerPhrase] = useState("");

  useEffect(() => {
    if (!props.helpDialogShown) {
      return;
    }

    let rng = Math.floor(Math.random() * 9); // 0 to 8
    let leftOrRight = (Math.floor(Math.random() * 2) + 1) % 2 === 0 ? 'left' : 'right';

    if (rng === 0) {
      setFirstPlayerPhrase("");
    } else if (rng === 1) {
      setFirstPlayerPhrase(rng + "st player to the " + leftOrRight + " of the");
    } else if (rng === 2) {
      setFirstPlayerPhrase(rng + "nd player to the " + leftOrRight + " of the");
    } else if (rng === 3) {
      setFirstPlayerPhrase(rng + "rd player to the " + leftOrRight + " of the");
    } else {
      setFirstPlayerPhrase(rng + "th player to the " + leftOrRight + " of the");
    }
  }, [props.helpDialogShown]);

  const handleClose = () => {
    props.onClose(true);
  };

  return (
    <Dialog
    onClose={handleClose}
    aria-labelledby="customized-dialog-title"
    open={props.helpDialogShown}
    >
        <BootstrapDialogTitle sx={{ m:0 }} id="customized-dialog-title" onClose={handleClose}>
          How to Play
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography className={classes.itemPaddingBottom}>
            <strong>Catickory</strong> is a local party game where players have to associate a word for a chosen category before time runs out, but there's a catch...
          </Typography>
        <Divider />
          <Grid container spacing={1} className={classes.itemPadding}>
            <Grid item xs={4}><Typography align='center'><GroupOutlinedIcon/> 2-8 players</Typography></Grid>
            <Grid item xs={4}><Typography align='center'><TimerOutlinedIcon/> 10 min</Typography></Grid>
            <Grid item xs={4}><Typography align='center'><SmartphoneOutlinedIcon/> 1</Typography></Grid>
          </Grid>
          
        <Divider />
        <Typography className={classes.itemPadding}>
            <strong>Setup Game</strong><br/>
          </Typography>
            <ol className={classes.itemPaddingBottom}>
              <li>Choose or randomize a category.</li>
              <li>Set the seconds timer for each turn.</li>
              <li>Exclude unfun letters to play with.</li>
              <li>Set this device at the center of the group.</li>
              <li>Hit START to play.</li>
            </ol>
        <Divider />
          <Typography className={classes.itemPadding}>
            <strong>Objective</strong> is to be the last player standing.
          </Typography>
        <Divider />
          <Typography className={classes.itemPadding}>
            <strong>Starting player</strong> is the {firstPlayerPhrase} device owner.
          </Typography>
        <Divider />
          <Typography className={classes.itemPadding}>
            <strong>On Your Turn</strong><br/>
          </Typography>
          <Typography className={classes.itemPaddingBottom}>
            <strong>ANSWER</strong> with a word belonging or relating to the chosen category
            (i.e. If the category is "Animals", a valid answer would be "dog").
            However, the catch is you can only give an answer starting with a letter no one else has used yet
            (i.e. If "dog" is answered previously, "dolphin" would be an invalid answer).
            If the validity of an answer is in doubt, discretion is decided by the majority of the players. 
          </Typography>
          <Typography className={classes.itemPaddingBottom}>
            <strong>TAP</strong> the first letter of your answer to lock it in before time runs out. 
            If time runs out, you are eliminated which means you are skipped for the remainder of the game.
            Regardless of success or elimination, quickly pass the turn to the next player in clockwise order until all but one player remains. 
            On larger player counts, players may need to physically pass the device around.
          </Typography>
          <Typography className={classes.itemPaddingBottom}>
            <strong>OVERTIME</strong> happens when there are multiple remaining players after all the letters are answered. 
            The game will reset with a new random category, but the remaining players must now answer that new cateogry with two different starting letters before passing. 
          </Typography>
        <Divider />
        <center>
          <Typography className={classes.itemPadding}>
            There are over 200 built-in categories to get you started!
          </Typography>
          <Button className={classes.itemPaddingTop} sx={{ fontWeight: 'bold' }} variant="contained" color="success" onClick={handleClose}>
            Get Started
          </Button>
        </center>
        </DialogContent>
    </Dialog>
  );
}