import React, { useState, useEffect, Fragment, useRef } from 'react';
import Grid from '@mui/material/Grid';
import GameKeyboard from '../Keyboard/GameKeyboard';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import useSound from 'use-sound';
import correctSfx from '../../assets/sounds/correct.mp3';
import wrongSfx from '../../assets/sounds/wrong.mp3';
import levelUpSfx from '../../assets/sounds/level-up.mp3';
import * as CategoryUtil from '../../constants/CategoryUtil';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import SettingsBackupRestoreRoundedIcon from '@mui/icons-material/SettingsBackupRestoreRounded';

import Divider from '@mui/material/Divider';
import Countdown, { zeroPad } from 'react-countdown';

import classes from './Game.module.css';

const Game = (props) => {

  const DEFAULT_COLOR = 'text.primary';
  const LOSE_COLOR = 'error.main';
  const WIN_COLOR = 'success.main';
  const WIN_FREEZE_TIME = 250;

  const [countdown, setCountdown] = useState(Date.now() + (props.timer * 1000));
  const [roundNum, setRoundNum] = useState(1);
  const [playedLetters, setPlayedLetters] = useState(new Set());
  const [playedLettersStack, setPlayedLettersStack] = useState(new Array());
  const [numPlayedLettersInTurn, setNumPlayedLettersInTurn] = useState(0);
  const [countdownColor, setCountdownColor] = useState(DEFAULT_COLOR); 
  const [isDisableKeyboard, setDisableKeyboard] = useState(false); 
  const [isShowUndoResumeButtons, setShowUndoResumeButtons] = useState(false); 

  const [playCorrectSfx] = useSound(correctSfx);
  const [playWrongSfx] = useSound(wrongSfx);
  const [playLevelUpSfx] = useSound(levelUpSfx);
  const playTickTockSfx = props.playTickTockSfx;
  const stopTickTockSfx = props.stopTickTockSfx;

  const isMounted = useRef(false);
  const countdownRef = useRef();
  const startCountdown = () => countdownRef.current.start();
  const pauseCountdown = () => countdownRef.current.pause();
  // const isCountdownStopped = () => countdownRef.current.isStopped();
  // const isCountdownPaused = () => countdownRef.current.isPaused();
  const isCountdownCompleted = () => countdownRef.current.isCompleted();

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true; // prevent running this useEffect onMount
      return;
    }
    let currentNumPlayedLettersInTurn = numPlayedLettersInTurn + 1;
    if (isCountdownCompleted()) { // undo: dont do anything
      console.log("Undo");
      return;
    } else if (playedLetters.size + props.excludedLetters.size === 26) { // go to overtime rounds
      console.log('Overtime ' + roundNum);
      stopTickTockSfx();
      playSoundIfOn(playLevelUpSfx);
      pauseCountdown();
      if (roundNum + 1 > (26 - props.excludedLetters.size)) { // end game (impossible to win due to num rounds and letters avl)
        console.log("Impossible to win");
        setDisableKeyboard(true);
        props.showTiedDialog();
      } else {
        props.setCategory(CategoryUtil.getRandomCategory())
        setRoundNum(roundNum + 1);
        setPlayedLetters(new Set());
        setPlayedLettersStack(new Array());
        setNumPlayedLettersInTurn(0);
        setCountdown(Date.now() + (props.timer * 1000));
      }
    } else if (roundNum === currentNumPlayedLettersInTurn) { // pass turn to next player // goes here on init
      console.log('Pass to next player: ' + currentNumPlayedLettersInTurn);
      stopTickTockSfx();
      playSoundIfOn(playCorrectSfx); 
      pauseCountdown();
      setCountdownColor(WIN_COLOR);
      setNumPlayedLettersInTurn(currentNumPlayedLettersInTurn);
      setTimeout(() => {
        setNumPlayedLettersInTurn(0);
        setCountdown(Date.now() + (props.timer * 1000));
     }, WIN_FREEZE_TIME);
    } else if (roundNum > 1 && playedLetters.size > 0) { // not yet satisifed multiple answers in overtime
      console.log('Continue playing');
      setNumPlayedLettersInTurn(currentNumPlayedLettersInTurn);
    }
  }, [playedLetters]);

  useEffect(() => {
    setCountdownColor(DEFAULT_COLOR);
    setDisableKeyboard(false);
    playTickTockSfx();
    startCountdown();
  }, [countdown]);

  const handleAddPlayedLetter = (addLetter) => {
    setPlayedLetters((prevPlayedLetters) => {
      const newPlayedLetters = new Set(prevPlayedLetters.values());
      newPlayedLetters.add(addLetter);
      return newPlayedLetters;
    });
    setPlayedLettersStack((prevPlayedLetters) => {
      const newPlayedLetters = [...prevPlayedLetters];
      newPlayedLetters.push(addLetter);
      return newPlayedLetters;
    });
  };

  const handleRemovePlayedLetter = (removeLetter) => {
    setPlayedLetters((prevPlayedLetters) => {
      const newPlayedLetters = new Set(prevPlayedLetters.values());
      newPlayedLetters.delete(removeLetter);
      return newPlayedLetters;
    });
    setPlayedLettersStack((prevPlayedLetters) => {
      const newPlayedLetters = [...prevPlayedLetters];
      newPlayedLetters.pop();
      return newPlayedLetters;
    });
  };

  const playSoundIfOn = (playSound) => {
    if (props.isSound) {
      playSound();
    }
  }

  const handleRestartCountdown = () => {
    setShowUndoResumeButtons(false);
    setNumPlayedLettersInTurn(0);
    setCountdown(Date.now() + (props.timer * 1000));
  }

  const handleOnCountdownComplete = () => {
    playSoundIfOn(playWrongSfx);
    playSoundIfOn(stopTickTockSfx);
    setShowUndoResumeButtons(true);
    setDisableKeyboard(true);
  }

  const handleUndoPlayedLetter = () => {
    if (playedLettersStack.length === 0 || roundNum > (26 - props.excludedLetters.size)) {
      return;
    }
    let letterToRemove = playedLettersStack[playedLettersStack.length - 1];
    handleRemovePlayedLetter(letterToRemove);
  }


  const OutOfTime = () => (<Box component="span" sx={{ color: LOSE_COLOR }}>OUT OF TIME </Box>);

  const renderer = ({ seconds, milliseconds, completed }) => {
    if (completed) { // complete
      return <OutOfTime />;
    } else { // countdown
      let millisTwoDigit = milliseconds;
      if (String(milliseconds).length > 2) {
        millisTwoDigit = String(milliseconds).substring(0,2);
      } else if (String(milliseconds).length === 2) {
        millisTwoDigit = '0' + String(milliseconds).substring(0,1);
      } else if (String(milliseconds).length < 2) {
        millisTwoDigit = '00';
      }
      return (
        <Box component="span">
          {zeroPad(seconds,2)}:{millisTwoDigit}
        </Box>
      );
    }
  };

  return ( 
    <Fragment>
      <Grid item container display="flex" justifyContent="space-between" alignItems="center" xs={12}>
          
            <Box justifyContent="flex-start">
              {isShowUndoResumeButtons ? 
              (<IconButton className={classes.undoButton} aria-label="undo" size="large" onClick={handleUndoPlayedLetter} disableRipple>
                <SettingsBackupRestoreRoundedIcon fontSize="inherit" />
              </IconButton>) : ''}
            </Box>
            <Box justifyContent="center">
              <Typography  variant="h5">{props.category + (roundNum > 1 ? ' (' + numPlayedLettersInTurn + '/' + roundNum + ')' : '')}</Typography>
            </Box>
            
            <Box justifyContent="flex-end">
              {isShowUndoResumeButtons ? 
              (<IconButton className={classes.restartButton} aria-label="restart" size="large" onClick={handleRestartCountdown} disableRipple>
                <PlayCircleOutlineIcon className={classes.blink} fontSize="inherit" />
              </IconButton>) : ''}
            </Box>
      </Grid>

      <Grid item xs={12}>
        <Divider />
        <Typography variant="h4" 
                    color={countdownColor}
                    sx={{ 
                    flexGrow: 1, 
                    fontFamily: "'Major Mono Display', sans-serif"}}>
          <Countdown date={countdown} precision={2} intervalDelay={0} renderer={renderer} ref={countdownRef} onComplete={handleOnCountdownComplete} />
        </Typography>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <GameKeyboard isAllDisabled={isDisableKeyboard} excludedLetters={props.excludedLetters} playedLetters={playedLetters} addPlayedLetter={handleAddPlayedLetter}/>
      </Grid>    
    </Fragment>
  ); 
}

export default Game;