import { createTheme } from '@mui/material/styles';

const UBE = '#5b578a';

const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: UBE
      }
    },
});

const lightTheme = createTheme({
    palette: {
        type: 'light',
        primary: {
          main: UBE
        }
    }
});

export { darkTheme, lightTheme }