import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import classes from './HelpDialog.module.css'
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import SmartphoneOutlinedIcon from '@mui/icons-material/SmartphoneOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import Grid from '@mui/material/Grid';

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function TiedDialog(props) {

  const handleEndGame = () => {
    props.endGame();
    props.onClose(true);
  }

  return (
    <Dialog
    onClose={handleEndGame}
    aria-labelledby="customized-dialog-title"
    open={props.tiedDialogShown}
    >
        <BootstrapDialogTitle sx={{ m:0 }} id="customized-dialog-title" onClose={handleEndGame}>
          Tied
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography className={classes.itemPaddingBottom}>
            <strong>Victory is shared among all remaining players!</strong><br/>
            There are not enough letters for the next overtime.
          </Typography>
        <center>
          <Button className={classes.itemPaddingTop} sx={{ fontWeight: 'bold' }} variant="contained" color="success" onClick={handleEndGame}>
            End Game
          </Button>
        </center>
        </DialogContent>
    </Dialog>
  );
}