import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import classes from './SettingsDialog.module.css';
import Switch from '@mui/material/Switch';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import coffeeImage from '../../assets/ko-fi.png';
import Button from '@mui/material/Button';

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function StatsDialog(props) {

  const handleClose = () => {
    props.onClose(true);
  };

  const handleEndGame = () => {
    props.endGame();
    props.onClose(true);
  }

  return (
    <Dialog
    onClose={handleClose}
    aria-labelledby="customized-dialog-title"
    open={props.statsDialogShown}
    >
        <BootstrapDialogTitle sx={{ m:0 }} id="customized-dialog-title" onClose={handleClose}>
          Settings
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography className={classes.itemPaddingBottom}>
            <strong>Sound</strong><Switch className={classes.statsSwitch} checked={props.isSound} onChange={props.toggleSound}/>
          </Typography>
          <Divider />
          <Typography className={classes.itemPadding}>
            <strong>Tick Tock</strong><Switch className={classes.statsSwitch} checked={props.isTickTockSound} onChange={props.toggleTickTockSound} disabled={!props.isSound}/>
          </Typography>
          <Divider />
          <Typography className={classes.itemPadding}>
            <strong>Dark Mode</strong><Switch className={classes.statsSwitch} checked={props.darkMode} onChange={props.toggleDarkMode} />
          </Typography>
          <center>
            <Button variant="contained" color="secondary" target="_blank" href="https://rentumbokon.com/support.html">
                <img className={classes.bmcImage} src={coffeeImage} alt="Support me!" />Buy me a Coffee <ArrowForwardIcon className={classes.bmcArrow} />
            </Button>
            {props.isPlay ? 
            (<Typography className={classes.itemPaddingTop}>
                <Button onClick={handleEndGame} variant="contained" color="error">
                  <strong>End Game</strong>
                </Button>
            </Typography>) : ''}
          </center>
        </DialogContent>
    </Dialog>
  );
}