import React, { Fragment, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

const NumKey = styled(Button)({
  fontSize: 40,
  marginBottom: 10,
  minWidth: '90%',
  height: '80px',
  lineHeight: 1.5,
  backgroundColor: 'primary',
});

const GameKeyboard = (props) => {

  const handleClick = (event) => {
    let letter = event.target.textContent;
    if (props.playedLetters.has(letter)) {
      props.removePlayedLetter(letter);
    } else {
      props.addPlayedLetter(letter);
    }
  }

  const getVariant = (letter) => { // todo: it rerenders every NumKey, optimize to render only one?
    if (props.playedLetters.has(letter)) {
      return 'outlined';
    } else {
      return 'contained'
    }
  }
  
  return (
    <Fragment>
      <Grid container spacing={0.5}>
        {props.excludedLetters.has('A') ? '' : <Grid item xs={3}><NumKey variant={getVariant('A')} onClick={(e) => handleClick(e)} disabled={props.playedLetters.has('A') || props.isAllDisabled}>A</NumKey></Grid>}
        {props.excludedLetters.has('B') ? '' : <Grid item xs={3}><NumKey variant={getVariant('B')} onClick={(e) => handleClick(e)} disabled={props.playedLetters.has('B') || props.isAllDisabled}>B</NumKey></Grid>}
        {props.excludedLetters.has('C') ? '' : <Grid item xs={3}><NumKey variant={getVariant('C')} onClick={(e) => handleClick(e)} disabled={props.playedLetters.has('C') || props.isAllDisabled}>C</NumKey></Grid>}
        {props.excludedLetters.has('D') ? '' : <Grid item xs={3}><NumKey variant={getVariant('D')} onClick={(e) => handleClick(e)} disabled={props.playedLetters.has('D') || props.isAllDisabled}>D</NumKey></Grid>}
        {props.excludedLetters.has('E') ? '' : <Grid item xs={3}><NumKey variant={getVariant('E')} onClick={(e) => handleClick(e)} disabled={props.playedLetters.has('E') || props.isAllDisabled}>E</NumKey></Grid>}
        {props.excludedLetters.has('F') ? '' : <Grid item xs={3}><NumKey variant={getVariant('F')} onClick={(e) => handleClick(e)} disabled={props.playedLetters.has('F') || props.isAllDisabled}>F</NumKey></Grid>}
        {props.excludedLetters.has('G') ? '' : <Grid item xs={3}><NumKey variant={getVariant('G')} onClick={(e) => handleClick(e)} disabled={props.playedLetters.has('G') || props.isAllDisabled}>G</NumKey></Grid>}
        {props.excludedLetters.has('H') ? '' : <Grid item xs={3}><NumKey variant={getVariant('H')} onClick={(e) => handleClick(e)} disabled={props.playedLetters.has('H') || props.isAllDisabled}>H</NumKey></Grid>}
        {props.excludedLetters.has('I') ? '' : <Grid item xs={3}><NumKey variant={getVariant('I')} onClick={(e) => handleClick(e)} disabled={props.playedLetters.has('I') || props.isAllDisabled}>I</NumKey></Grid>}
        {props.excludedLetters.has('J') ? '' : <Grid item xs={3}><NumKey variant={getVariant('J')} onClick={(e) => handleClick(e)} disabled={props.playedLetters.has('J') || props.isAllDisabled}>J</NumKey></Grid>}
        {props.excludedLetters.has('K') ? '' : <Grid item xs={3}><NumKey variant={getVariant('K')} onClick={(e) => handleClick(e)} disabled={props.playedLetters.has('K') || props.isAllDisabled}>K</NumKey></Grid>}
        {props.excludedLetters.has('L') ? '' : <Grid item xs={3}><NumKey variant={getVariant('L')} onClick={(e) => handleClick(e)} disabled={props.playedLetters.has('L') || props.isAllDisabled}>L</NumKey></Grid>}
        {props.excludedLetters.has('M') ? '' : <Grid item xs={3}><NumKey variant={getVariant('M')} onClick={(e) => handleClick(e)} disabled={props.playedLetters.has('M') || props.isAllDisabled}>M</NumKey></Grid>}
        {props.excludedLetters.has('N') ? '' : <Grid item xs={3}><NumKey variant={getVariant('N')} onClick={(e) => handleClick(e)} disabled={props.playedLetters.has('N') || props.isAllDisabled}>N</NumKey></Grid>}
        {props.excludedLetters.has('O') ? '' : <Grid item xs={3}><NumKey variant={getVariant('O')} onClick={(e) => handleClick(e)} disabled={props.playedLetters.has('O') || props.isAllDisabled}>O</NumKey></Grid>}
        {props.excludedLetters.has('P') ? '' : <Grid item xs={3}><NumKey variant={getVariant('P')} onClick={(e) => handleClick(e)} disabled={props.playedLetters.has('P') || props.isAllDisabled}>P</NumKey></Grid>}
        {props.excludedLetters.has('Q') ? '' : <Grid item xs={3}><NumKey variant={getVariant('Q')} onClick={(e) => handleClick(e)} disabled={props.playedLetters.has('Q') || props.isAllDisabled}>Q</NumKey></Grid>}
        {props.excludedLetters.has('R') ? '' : <Grid item xs={3}><NumKey variant={getVariant('R')} onClick={(e) => handleClick(e)} disabled={props.playedLetters.has('R') || props.isAllDisabled}>R</NumKey></Grid>}
        {props.excludedLetters.has('S') ? '' : <Grid item xs={3}><NumKey variant={getVariant('S')} onClick={(e) => handleClick(e)} disabled={props.playedLetters.has('S') || props.isAllDisabled}>S</NumKey></Grid>}
        {props.excludedLetters.has('T') ? '' : <Grid item xs={3}><NumKey variant={getVariant('T')} onClick={(e) => handleClick(e)} disabled={props.playedLetters.has('T') || props.isAllDisabled}>T</NumKey></Grid>}
        {props.excludedLetters.has('U') ? '' : <Grid item xs={3}><NumKey variant={getVariant('U')} onClick={(e) => handleClick(e)} disabled={props.playedLetters.has('U') || props.isAllDisabled}>U</NumKey></Grid>}
        {props.excludedLetters.has('V') ? '' : <Grid item xs={3}><NumKey variant={getVariant('V')} onClick={(e) => handleClick(e)} disabled={props.playedLetters.has('V') || props.isAllDisabled}>V</NumKey></Grid>}
        {props.excludedLetters.has('W') ? '' : <Grid item xs={3}><NumKey variant={getVariant('W')} onClick={(e) => handleClick(e)} disabled={props.playedLetters.has('W') || props.isAllDisabled}>W</NumKey></Grid>}
        {props.excludedLetters.has('X') ? '' : <Grid item xs={3}><NumKey variant={getVariant('X')} onClick={(e) => handleClick(e)} disabled={props.playedLetters.has('X') || props.isAllDisabled}>X</NumKey></Grid>}
        {props.excludedLetters.has('Y') ? '' : <Grid item xs={3}><NumKey variant={getVariant('Y')} onClick={(e) => handleClick(e)} disabled={props.playedLetters.has('Y') || props.isAllDisabled}>Y</NumKey></Grid>}
        {props.excludedLetters.has('Z') ? '' : <Grid item xs={3}><NumKey variant={getVariant('Z')} onClick={(e) => handleClick(e)} disabled={props.playedLetters.has('Z') || props.isAllDisabled}>Z</NumKey></Grid>}
      </Grid>
    </Fragment>
  );
};

export default GameKeyboard;