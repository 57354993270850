import React, { useState, useEffect, Fragment } from 'react';
import Grid from '@mui/material/Grid';
import SettingsKeyboard from '../Keyboard/SettingsKeyboard';

import TextField from '@mui/material/TextField';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';

import * as CategoryUtil from '../../constants/CategoryUtil';

import Typography from '@mui/material/Typography';

import classes from './SetUp.module.css';

const Settings = (props) => {

  const DEFAULT_TIMER = 10;
  const DEFAULT_EXCLUDED_LETTERS = new Set(['K','Q','V','X','Y','Z']);

  const [category, setCategory] = useState('');
  const [timer, setTimer] = useState(DEFAULT_TIMER);
  const [excludedLetters, setExcludedLetters] = useState(DEFAULT_EXCLUDED_LETTERS);
  const [isCategoryTouched, setCategoryTouched] = useState(false);

  // const [prevTimer, setPrevTimer] = useLocalStorage('timer', null);
  // const [prevExcludedLetters, setPrevExcludedLetters] = useLocalStorage('excludedLetters', null);

  const handleSliderChange = (event, newValue) => {
    setTimer(newValue);
  };
  const handleInputChange = (event) => {
    let newValue = event.target.value;
    setTimer(newValue === '' ? '' : Number(newValue));
  };
  const handleBlur = () => {
    if (timer < 1) {
      setTimer(1);
    } else if (timer > 60) {
      setTimer(60);
    }
  };
  const handleDefaultTimerButton = () => {
    setTimer(DEFAULT_TIMER);
  };

  const handleAddExcludedLetter = (addLetter) => {
    setExcludedLetters((prevExcludedLetters) => {
      const newExcludedLetters = new Set(prevExcludedLetters.values());
      newExcludedLetters.add(addLetter);
      return newExcludedLetters;
    });
  }
  const handleRemoveExcludedLetter = (removeLetter) => {
    setExcludedLetters((prevExcludedLetters) => {
      const newExcludedLetters = new Set(prevExcludedLetters.values());
      newExcludedLetters.delete(removeLetter);
      return newExcludedLetters;
    });
  }
  
  const handleSetCategory = (event) => {
    setCategoryTouched(true);
    let category = event.target.value;
    setCategory(category);
  }

  const handleRandomCategory = () => {
    setCategoryTouched(true);
    let randomCategory = CategoryUtil.getRandomCategory();
    setCategory(randomCategory);
  };

  const handleRecommendedLetters = () => {
    setExcludedLetters(new Set(DEFAULT_EXCLUDED_LETTERS));
  };

  const handleStartGame = () => {
    props.setCategory(category);
    props.setTimer(timer);
    props.setExcludedLetters(excludedLetters);
    props.setPlay(true);
  };

  return (
    <Fragment>
      <Grid item xs={12}>
        <Typography variant="h5">
          Setup Game
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <Typography variant="h4">
          <TextField id="outlined-name" label="Category" variant="standard" 
          inputProps={{ maxLength: 50 }} 
          value={category} 
          onChange={handleSetCategory} 
          error={isCategoryTouched && category.length === 0}
          fullWidth required/>
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="h4">
          <Button className={classes.randomButton} variant="text" onClick={handleRandomCategory}>Random</Button>
        </Typography>
      </Grid>

      <Grid item xs={10}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <TimerOutlinedIcon />
          </Grid>
          <Grid item xs>
            <Slider
              value={typeof timer === 'number' ? timer : 0}
              onChange={handleSliderChange}
              aria-labelledby="input-slider"
              min={1}
              max={60}
            />
          </Grid>
          <Grid item>
            <MuiInput
              value={timer}
              size="small"
              onChange={handleInputChange}
              onBlur={handleBlur}
              inputProps={{
                step: 1,
                min: 1,
                max: 60,
                type: 'number',
                'aria-labelledby': 'input-slider',
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="h4">
          <Button className={classes.defaultButton} variant="text" onClick={handleDefaultTimerButton}>Default</Button>
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <SettingsKeyboard excludedLetters={excludedLetters} addExcludedLetter={handleAddExcludedLetter} removeExcludedLetter={handleRemoveExcludedLetter} />
        <Typography variant="h4">
          <Button variant="text" onClick={handleRecommendedLetters}>Recommended Letters</Button>
        </Typography>    
      </Grid>    

      <Grid item xs={12}>
        <Button sx={{ fontWeight: 'bold' }} variant="contained" color="success" onClick={handleStartGame} disabled={category.length === 0}>Play</Button>
      </Grid>
    </Fragment>
  );
}

export default Settings;
